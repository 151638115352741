<template>
  <div class="list row">
    <div class="col-md-6">
      <h4>Programações</h4>
      <ul class="list-group">
        <li class="list-group-item"
          :class="{ active: index == currentIndex }"
          v-for="(schedule, index) in schedules"
          :key="index"
          @click="setActiveSchedule(schedule, index)"
        >
          {{ ('00'+schedule.hour).substr(-2) }}:{{ ('00'+schedule.minute).substr(-2) }}
        </li>
      </ul>

      <button class="m-3 btn btn-sm btn-danger" @click="removeAllSchedules">
        Remover todas
      </button>
    </div>
    <div class="col-md-6">
      <div v-if="currentSchedule">
        <h4>Schedule</h4>
        <div>
          <label><strong>Hora:</strong></label> {{ ('00'+currentSchedule.hour).substr(-2) }}:{{ ('00'+currentSchedule.minute).substr(-2) }}
        </div>
        <div>
          <label><strong>Dias:</strong></label>
          {{ currentSchedule.day0 ? "domingo" : "" }}
          {{ currentSchedule.day1 ? "segunda" : "" }}
          {{ currentSchedule.day2 ? "terça" : "" }}
          {{ currentSchedule.day3 ? "quarta" : "" }}
          {{ currentSchedule.day4 ? "quinta" : "" }}
          {{ currentSchedule.day5 ? "sexta" : "" }}
          {{ currentSchedule.day6 ? "sábado" : "" }}
        </div>
        <div>
          <label><strong>Status:</strong></label> {{ currentSchedule.active ? "Ativado" : "Desativado" }}
        </div>

        <router-link :to="'/schedules/' + currentSchedule.id" class="badge badge-warning">Editar</router-link>
      </div>
      <div v-else>
        <br />
        <small>Clique em uma programação para editar.</small>
      </div>
    </div>
  </div>
</template>

<script>
import ScheduleDataService from "../services/ScheduleDataService";

export default {
  name: "schedules-list",
  data() {
    return {
      schedules: [],
      currentSchedule: null,
      currentIndex: -1,
      title: ""
    };
  },
  methods: {
    retrieveSchedules() {
      ScheduleDataService.getAll()
        .then(response => {
          this.schedules = response.data;
          console.log(response.data);
        })
        .catch(e => {
          console.log(e);
        });
    },

    refreshList() {
      this.retrieveSchedules();
      this.currentSchedule = null;
      this.currentIndex = -1;
    },

    setActiveSchedule(schedule, index) {
      this.currentSchedule = schedule;
      this.currentIndex = schedule ? index : -1;
    },

    removeAllSchedules() {
      ScheduleDataService.deleteAll()
        .then(response => {
          console.log(response.data);
          this.refreshList();
        })
        .catch(e => {
          console.log(e);
        });
    },
    
    searchTitle() {
      ScheduleDataService.findByTitle(this.title)
        .then(response => {
          this.schedules = response.data;
          this.setActiveSchedule(null);
          console.log(response.data);
        })
        .catch(e => {
          console.log(e);
        });
    }
  },
  mounted() {
    this.retrieveSchedules();
  }
};
</script>

<style>
.list {
  text-align: left;
  max-width: 750px;
  margin: auto;
}
</style>
